import actions from './actions';
import mutations from './mutations';

const events = {
	state:{
		events: [], // список текущих ивентов
		fetchEventsCounter: 0,
		activeEvent: {}, // занятие по которому кликнули (из ещё не прошедших)
		activeEventFreeSlots: null,
		isBooked: false,
		isRefused: false,
		isWaiting: false,
		bookingError: '',
		eventsIsFetched: false,
		//phoneMask: '',
		//phoneMaskPlaceholder: '',
		defaultCountry: '',
		preferredCountries: [],
		secondaryWidgetColor: '#f7f9fd',
		primaryWidgetColor: '#3c67d6',
		offer: null,
		clubRules: null,
		personalData: null,
		isNeedRegisterBySchedule: 0 // Отключена ли запись через виджет
	},
	getters:{
		events: state => state.events,
		fetchEventsCounter: state => state.fetchEventsCounter,
		activeEvent: state => state.activeEvent,
		activeEventFreeSlots: state => state.activeEventFreeSlots,
		isBooked: state => state.isBooked,
		isRefused: state => state.isRefused,
		bookingError: state => state.bookingError,
		eventsIsFetched: state => state.eventsIsFetched,
		//phoneMask: state => state.phoneMask,
		//phoneMaskPlaceholder: state => state.phoneMaskPlaceholder,
		defaultCountry: state => state.defaultCountry,
		preferredCountries: state => state.preferredCountries,
		secondaryWidgetColor: state => state.secondaryWidgetColor,
		primaryWidgetColor: state => state.primaryWidgetColor,
		offer: state => state.offer,
		clubRules: state => state.clubRules,
		personalData: state => state.personalData,
		isWaiting: state => state.isWaiting,
		isNeedRegisterBySchedule: state => state.isNeedRegisterBySchedule,
	},
	mutations: mutations,
	actions: actions,
	namespaced: true,
};

export default events;

<template>
	<div class="days_traveller_wrapper" v-touch:swipe="swipe">
		<div class="days_traveller" ref="daysTraveller">
			<Week
				v-for="(week_item, week_index) in this.allDaysList"
				:key="`week_${week_index}`"
				v-bind:class="[(week_index === weekIndex) ? 'all_week active' : 'all_week']"
				:daysList="week_item"
			/>
		</div>
	</div>
</template>
<script>
import moment from "moment";
import {storeToComputed} from "../../../mixins/storeToComputed";
import {DAYS_RU, DAYS_RU_SHORT, MONTH_RU} from "../../../../utils/dates";
import {eventBus} from "../../../../main";
import Week from "./Week";
import * as constants from '../constants';

export default {
	name: "DaysTraveller",
	components: {
		Week,
	},
	mixins: [storeToComputed],
	created() {
		this.setDaysList();

		eventBus.$on('swipeAnimate', (direction) => {
			this.swipeAnimate(direction);
		});

		eventBus.$on('goToNewDate', (event) => {
			this.goToDate(event);
		});

		eventBus.$on('updateDates', () => {
			this.setActive(this.start);
		});

		eventBus.$on('resetAllMenuFilters', () => {
			const container = this.getContainer();
			const today = container.querySelector('.day_item.today');
			const dayId = Number(today.dataset.dayId);
			this.$store.dispatch('calendar/setActiveDayIndex', dayId);
		});
	},
	computed: {
		weekIndex: function() {
			return this.currentWeekIndex;
		}
	},
	methods: {
		isOnWeek: function(list) {
			let array = [];
			const keys = Object.keys(list);
			for (const key of keys) {
				array.push(list[key])
			}

			return array.some(day => day.id === this.activeDayIndex);
		},
		getContainer: function () {
			return this.$refs.daysTraveller;
		},
		setDaysList: function () {
			moment.updateLocale("en", {
				week: {
					dow: 1,
				}
			});

			const today = moment();
			let start_date = today.clone().startOf('isoWeek');
			let lastDayEvent = (this.scheduleNextWeek.isHavingSchedulesNextWeek) ? moment(this.scheduleNextWeek.date) : today.clone().startOf('isoWeek');
			let startWeekIndex = start_date.week();
			let endWeekIndex = startWeekIndex <= lastDayEvent.week() ? lastDayEvent.week() : lastDayEvent.week() + 52; // отпредеяем неделю последнего события

			let period = [{
				start: today.clone().startOf('isoWeek'),
				end: today.clone().endOf('isoWeek').subtract(1,'days')
			}];
			let index = 1;
			for (let i = startWeekIndex; i < endWeekIndex; i++) {
				period.push({
					start: today.clone().add(index, 'weeks').startOf('isoWeek'),
					end: today.clone().add(index, 'weeks').endOf('isoWeek').subtract(1,'days'),
				});
				index++;
				// ещё какие-то выражения
			}
			period.forEach((value, index) => {
				if (index === 0) {
					this.setWeekDaysNew(index, value, today);
				} else {
					this.setWeekDaysNew(index, value);
				}
			}, this);
		},
		setWeekDaysNew: function (weekIndex, weekEdges) {
			const {start, end} = weekEdges;
			let weekDays = {};

			let counter = weekIndex * 7;
			const today = moment();
			const todayNumber = today.format('D');
			const todayLabel = DAYS_RU[today.format('dddd')];
			const todayMonth = MONTH_RU[today.format('MMMM')];

			while (end.diff(start, 'days') >= 0) {
				let isToday = false;
				let date = start.format('YYYY-MM-DD');
				let number = start.format('D');
				let labelShort = DAYS_RU_SHORT[start.format('dddd')];
				let label = DAYS_RU[start.format('dddd')];
				let month = MONTH_RU[start.format('MMMM')];
				isToday = (todayNumber === number) && (todayLabel === label) &&
						(todayMonth === month);

				weekDays[counter] = {
					'id': counter,
					'date': date,
					'month': month,
					'number': number,
					'labelShort': labelShort,
					'label': label,
					'active': isToday,
					'today': isToday,
				};

				start.add(1, 'days')
				counter++;
			}
			this.$store.dispatch('calendar/addWeekAllDaysList', { index: weekIndex, value: weekDays })
		},

		setActive: function (date) {
			const container = this.getContainer();
			const active = container.querySelector('.day_item.active');
			if (active) {
				active.classList.remove('active');
			}
			const needleItem = container.querySelector('.day_item[data-date="' + date + '"]');
			if (needleItem) {
				needleItem.classList.add('active');
			}
		},
		goToDate: function (event) {
			const target = event.target;
			const container = this.getContainer();
			const dayItem = target.closest('.day_item');
			if (dayItem && !dayItem.classList.contains('active')) {
				const date = dayItem.dataset.date;
				const active = container.querySelector('.day_item.active');
				if (active) {
					active.classList.remove('active');
				}
				dayItem.classList.add('active');
				const dayId = Number(dayItem.dataset.dayId);
				this.$store.dispatch('calendar/setActiveDayIndex', dayId);
				eventBus.$emit('gotToDate', {date: date});
				eventBus.$emit('setActiveDay', dayId);
			}
		},
		swipe(direction) {
			if (direction === constants.DIRECTION_BACKWARD) {
				if (this.currentWeekIndex === 0) {
					return false;
				} else {
					eventBus.$emit('dateTravellerBackward');
				}
			} else if (direction === constants.DIRECTION_FORWARD) {
				const weeks_index_array = Object.keys(this.allDaysList);
				weeks_index_array.length;
				if (this.currentWeekIndex < weeks_index_array.length -1) {
					eventBus.$emit('dateTravellerForward');
				} else {
					return false;
				}
			}
		},
	},
}
</script>

<template>
	<div>
		<div class="event_trainer event_trainer_replacement" ref="eventTrainerReplacement" v-if="trainer.replacement">
			<div class="event_trainer_avatar">
				<img :src="`${trainerReplacement.avatar}`" alt="Тренер по замене"/>
			</div>
			<div class="event_trainer_right">
				<div class="event_trainer_right_top">
					<div class="event_trainer_surname">
						{{trainerReplacement.surname}} {{trainerReplacement.name}}
					</div>
				</div>
			</div>
		</div>
		<div class="event_trainer" ref="eventTrainer">
			<div class="event_trainer_avatar" v-bind:class="{ strike: trainer.replacement }">
				<img :src="`${trainer.avatar}`" alt="Тренер"/>
			</div>
			<div class="event_trainer_right">
				<div class="event_trainer_right_top">
					<div class="event_trainer_surname" v-bind:class="{ strike: trainer.replacement }">
						{{trainer.surname}} {{trainer.name}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "EventModalTrainer",
	props: {
		trainer: {
			type: Object,
			default: function () {
				return {}
			}
		},
		trainerReplacement: {
			type: Object,
			default: function () {
				return {}
			}
		},
		domain: {
			type: String,
			default: ''
		}
	},
}
</script>

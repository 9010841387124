<template>
	<div class="date_traveller" v-if="Object.keys(this.allDaysList).length !== 0">
		<IconBase class="date_traveller_btn date_traveller_btn_prev"
			v-if="this.currentWeekIndex > 0"
			@click.native="backward"
			icon-name="chevron-left"
			width="11"
			height="18"
			viewBox="0 0 11 18"
			title="Назад"
			:no-divider="true"
		>
			<IconChevronLeft/>
		</IconBase>
		<DayItem :day="activeDay" v-if="Object.keys(activeDay).length !== 0"/>
		<IconBase class="date_traveller_btn date_traveller_btn_next"
			v-if="this.currentWeekIndex < (countWeeks - 1)"
			@click.native="forward"
			icon-name="chevron-right"
			width="11"
			height="18"
			viewBox="0 0 11 18"
			title="Вперед"
			:no-divider="true"
		>
			<IconChevronRight/>
		</IconBase>
	</div>
</template>

<script>

import moment from "moment";
import {storeToComputed} from "../../../mixins/storeToComputed";
import {DAYS_RU, MONTH_RU} from "../../../../utils/dates";
import IconBase from "../../../icons/IconBase";
import IconChevronLeft from "../../../icons/IconChevronLeft";
import IconChevronRight from "../../../icons/IconChevronRight";
import DayItem from './DayItem'
import {eventBus} from "../../../../main";
import * as constants from "../constants";

export default {
	name: "DateTraveller",
	components: {
		DayItem,
		IconBase,
		IconChevronLeft,
		IconChevronRight
	},
	mixins: [storeToComputed],
	data() {
		return {
			daysList: {},
		}
	},
	computed: {
		activeDay() {
			return this.allDaysList[this.currentWeekIndex][this.activeDayIndex];
		},
		countWeeks() {
			const weeks_index_array = Object.keys(this.allDaysList);
			return weeks_index_array.length;
		},
	},
	created() {
		this.DAYS_IN_WEEK_COUNT = constants.DAYS_IN_WEEK_COUNT;
		eventBus.$on('setActiveDay', (dayId) => {
			this.setDayListActiveByDay(dayId);
		});
		eventBus.$on('dateTravellerBackward', () => {
			this.backward();
		});
		eventBus.$on('dateTravellerForward', () => {
			this.forward();
		});

		this.setDaysList();
	},
	methods: {
		/**
		 * Устанавливаем активный день по его id
		 * @param dayId
		 */
		setDayListActiveByDay: function (dayId) {
			//актуализируем состояние списка дней
			const dayList = this.daysList;
			const daysArray = this.getArrayFromObject(dayList);
			daysArray.forEach((day) => {
				this.daysList[day.id]['active'] = day.id === dayId
			});
		},
		/**
		 * Устанавливаем активный день по его дате
		 * @param date
		 */
		setDayListActiveByDate: function (date) {
			//актуализируем состояние списка дней
			const dayList = this.daysList;
			const daysArray = this.getArrayFromObject(dayList);
			daysArray.forEach((day) => {
				this.daysList[day.id]['active'] = day.date === date
			});
		},
		getArrayFromObject: function (object) {
			let array = [];
			const keys = Object.keys(object);
			for (const key of keys) {
				array.push(object[key])
			}
			return array;
		},
		goToDate: function (newDate) {
			this.setDayListActiveByDate(newDate);
			eventBus.$emit('gotToDate', {date: newDate});
		},
		backward: function () {
			if (this.currentWeekIndex !== 0) {
				this.$store.dispatch('calendar/setCurrentWeekIndex', this.currentWeekIndex - 1);
				let monday_index = (this.currentWeekIndex) * 7;
				this.$store.dispatch('calendar/setActiveDayIndex', monday_index);
				this.goToDate(this.allDaysList[this.currentWeekIndex][this.activeDayIndex]['date']);
			}
		},
		forward: function () {
			const weeks_index_array = Object.keys(this.allDaysList);
			if (this.currentWeekIndex < weeks_index_array.length) {
				this.$store.dispatch('calendar/setCurrentWeekIndex', this.currentWeekIndex + 1);
				let monday_index = (this.currentWeekIndex) * 7;
				this.$store.dispatch('calendar/setActiveDayIndex', monday_index);
				this.goToDate(this.allDaysList[this.currentWeekIndex][this.activeDayIndex]['date']);
			}
		},
		setDaysList: function () {
			moment.updateLocale("en", {
				week: {
					dow: 1,
				}
			});

			const today = moment();
			const todayNumber = today.format('D');
			const todayLabel = DAYS_RU[today.format('dddd')];
			const todayMonth = MONTH_RU[today.format('MMMM')];

			const dateStart = moment().startOf('week');
			const dateEnd = moment(this.scheduleNextWeek.date);

			let counter = 1;
			while (dateEnd.diff(dateStart, 'days') >= 0) {
				let date = dateStart.format('YYYY-MM-DD');
				let number = dateStart.format('D');
				let month = MONTH_RU[dateStart.format('MMMM')];
				let dayLabel = DAYS_RU[dateStart.format('dddd')];
				let isToday = (todayNumber === number) && (todayLabel === dayLabel) &&
					(todayMonth === month);

				if (isToday) {
					this.$store.dispatch('calendar/setActiveDayIndex', counter - 1);

				}

				this.daysList[counter] = {
					'id': counter,
					'date': date,
					'number': number,
					'month': month,
					'dayLabel': dayLabel,
					'active': isToday,
					'today': isToday
				};

				dateStart.add(1, 'days');
				counter++;
			}

			counter = null;
		},
	}
}
</script>

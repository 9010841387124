<template>
    <div class="header">
        <ShowFiltersButton @click.native="showFilters"/>

        <EnterButton />
    </div>
</template>

<script>
    import ShowFiltersButton from "./ShowFiltersButton";
    import EnterButton from "../../Filters/EnterButton";
    import {eventBus} from "../../../main";

    export default {
        name: "Header",
        components: {ShowFiltersButton, EnterButton},
        methods: {
          showFilters() {
              eventBus.$emit('showFilters',true);
          }
        },
    };
</script>

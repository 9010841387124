import moment from "moment";
import {storeToComputed} from "./storeToComputed";

export const getDayHeaderContent = {
	mixins: [storeToComputed],
	methods: {
		getDayHeader:function ({date}) {
			let momentObject = moment(date);
			let current = moment();

			momentObject.locale('ru');
			current.locale('ru');
			let dateFull = momentObject.format('dd, D MMMM');

			let	content = `<div class="fc-col-header_day_title">
                    <div class="fc-col-header_date">
                        ${dateFull}
                    </div>
                  </div>`;

			return {
				html: content
			}
		}
	},
	computed: {
		bg_primary() {
			return `color: ${this.$store.getters['events/primaryWidgetColor']};`;
		},
		bg_secondary() {
			return `background: ${this.$store.getters['events/secondaryWidgetColor']};`;
		}
	}
}

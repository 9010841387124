export const SET_EVENTS = 'SET_EVENTS';
export const CLEAR_EVENTS = 'CLEAR_EVENTS';
export const SET_ACTIVE_EVENT = 'SET_ACTIVE_EVENT';
export const CLEAR_ACTIVE_EVENT = 'CLEAR_ACTIVE_EVENT';
export const SET_EVENTS_IS_FETCHING = 'SET_EVENTS_IS_FETCHING';
export const SET_EVENTS_IS_FETCHED = 'SET_EVENTS_IS_FETCHED';
export const SET_ACTIVE_EVENT_FREE_SLOTS = 'SET_ACTIVE_EVENT_FREE_SLOTS';
export const SET_IS_BOOKED = 'SET_IS_BOOKED';
export const SET_IS_REFUSED = 'SET_IS_REFUSED';
export const SET_BOOKING_ERROR = 'SET_BOOKING_ERROR';
//export const SET_PHONE_MASK = 'SET_PHONE_MASK';
//export const SET_PHONE_MASK_PLACEHOLDER = 'SET_PHONE_MASK_PLACEHOLDER';
export const INCREASE_EVENTS_COUNTER = 'INCREASE_EVENTS_COUNTER';
export const SET_DEFAULT_COUNTRY = 'SET_DEFAULT_COUNTRY';
export const SET_PREFERRED_COUNTRIES = 'SET_PREFERRED_COUNTRIES';

export const SET_PRIMARY_COLOR = 'SET_PRIMARY_COLOR';
export const SET_SECONDARY_COLOR = 'SET_SECONDARY_COLOR';
export const SET_IS_WAITING = 'SET_IS_WAITING';

export const SET_OFFER = 'SET_OFFER';
export const SET_CLUB_RULES = 'SET_CLUB_RULES';
export const SET_PERSONAL_DATA = 'SET_PERSONAL_DATA';

export const SET_IS_NEED_REGISTER_BY_SCHEDULE = 'SET_IS_NEED_REGISTER_BY_SCHEDULE';
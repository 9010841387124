export const CALENDAR_RERENDER = 'CALENDAR_RERENDER'
export const SET_START = 'SET_START'
export const SET_END = 'SET_END'
export const SET_ACTIVE_DAY_INDEX = 'SET_ACTIVE_DAY_INDEX'
export const SET_FIRST_WEEK_DAYS_LIST = 'SET_FIRST_WEEK_DAYS_LIST'
export const SET_NEXT_WEEK_DAYS_LIST = 'SET_NEXT_WEEK_DAYS_LIST'
export const SET_SCHEDULE_NEXT_WEEK = 'SET_SCHEDULE_NEXT_WEEK'
export const SET_ACTIVE_EVENT_CHECK_FREE_SLOTS = 'SET_ACTIVE_EVENT_CHECK_FREE_SLOTS'
export const SET_FIRST_WEEK = 'SET_FIRST_WEEK'
export const SET_LAST_WEEK = 'SET_LAST_WEEK'
export const ADD_WEEK_ALL_DAYS_LIST = 'ADD_WEEK_ALL_DAYS_LIST'
export const SET_CURRENT_WEEK_INDEX = 'SET_CURRENT_WEEK_INDEX'
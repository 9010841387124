<template>
    <div :class="[{'is_booked': isBooked},'registration_form', event.stop_registration || event.cancel || (event.transfer && event.transfer.length !== 0) ? 'short' : '' ]"
         v-if="event.need_register">
        <FormSuccessBg v-if="this.isBooked"/>
        <FormWaitingBg v-if="this.isWaiting"/>
        <template
                v-if="(event.max_register - event.count_register !== 0) && !event.stop_registration && !event.cancel && (event.transfer && event.transfer.length === 0) && !isBooked && !isWaiting && !isRefused">
            <div class="registration_form_request" v-if="!event.require_visit_reason">
                <div class="registration_form_left">
                    <div class="caption">Требуется предварительная запись</div>
                    <div class="slots" v-if="event.hide_number_of_available_setting != 1">
                        Осталось: {{activeEventFreeSlots}} {{placesText}}
                    </div>
                </div>
                <div class="registration_form_right">
                    <EventModalPhoneForm @sendRegisrationRequest="sendRegisrationRequest"
                                         v-if="event.user.id === null"/>

                    <Button color="blue" size="m" :bg_color="this.bg" @click.native="sendRegisrationRequest"
                            v-if="event.user.id !== null && event.reservation_status === null">
                        Записаться
                    </Button>

                    <Button color="white" size="m" :bg_color="this.bg" @click.native="sendCancelRegistrationRequest"
                            v-if="event.user.id !== null && event.reservation_status !== null">
                        Отменить запись
                    </Button>
                </div>

                <div class="registration_form_agree" v-html="this.agreeText"></div>
            </div>
            <div class="registration_form_request" v-if="event.require_visit_reason && event.user.id === null">
                <div class="registration_form_left empty_slot_form_left">
                    <div class="caption">Для записи на занятие требуется основание,<br>авторизуйтесь в личном
                        кабинете
                    </div>
                </div>
                <EnterButton class="enter-button btn btn-blue btn-m btn-custom-primary"
                             :additional-params="`?target=/schedule?id=${event.event_id}`"/>
            </div>
            <div class="registration_form_request" v-if="event.require_visit_reason && event.user.id !== null">
                <div class="registration_form_left empty_slot_form_left">
                    <div class="caption closed">
                        У вас нет оснований для записи<br>на это занятие
                    </div>
                </div>
            </div>
        </template>
        <div class="registration_form_is_booked" v-else-if="isBooked">
            <div class="registration_form_is_booked_content">
                <div class="caption lg">Вы записаны!</div>
                <Button color="blue" size="m" @click.native="closeModal">
                    Вернуться к расписанию
                </Button>
            </div>
        </div>
        <div class="registration_form_is_booked" v-else-if="isWaiting">
            <div class="registration_form_is_booked_content">
                <div class="caption lg">Вы добавлены в лист ожидания!</div>
                <Button color="blue" size="m" @click.native="closeModal">
                    Вернуться к расписанию
                </Button>
            </div>
        </div>
        <div class="registration_form_is_booked" v-if="isRefused">
            <div class="registration_form_is_booked_content">
                <div class="caption lg">Запись отменена</div>
                <Button color="blue" size="m" @click.native="closeModal">
                    Вернуться к расписанию
                </Button>
            </div>
        </div>

        <template
                v-else-if="(event.stop_registration && event.time_open == '')|| event.cancel || (event.transfer && event.transfer.length !== 0) || (activeEventFreeSlots === 0 && !((event.max_register - event.count_register) === 0) && !isBooked && !isWaiting)">
            <div class="caption closed">Запись окончена</div>
        </template>
        <template v-else-if="event.stop_registration &&event.time_open !== ''">
            <div class="free_slots_error">Начало записи {{ event.time_open }}</div>
        </template>
        <template
                v-if="((event.max_register - event.count_register) === 0) && !event.stop_registration && !event.cancel && (event.transfer && event.transfer.length === 0) && !isBooked && !isWaiting && !isRefused">
            <div class="caption empty_slot">На данное занятие нет свободных мест.</div>
            <div class="registration_form_request" v-if="event.require_visit_reason && event.user.id === null">
              <div class="registration_form_left empty_slot_form_left">
                <div class="caption">Для записи на занятие требуется основание,<br>авторизуйтесь в личном
                  кабинете
                </div>
              </div>
              <EnterButton class="enter-button btn btn-blue btn-m btn-custom-primary"
                           :additional-params="`?target=/schedule?id=${event.event_id}`"/>
            </div>

            <div class="registration_form_request" v-if="event.reservation_status === null && (event.user.id !== null || !event.require_visit_reason)">
                <div class="registration_form_left empty_slot_form_left">
                    <div class="caption">
                      Записаться в лист ожидания
                    </div>
                </div>
                <div class="registration_form_right">
                    <Button color="blue" size="m" :bg_color="this.bg" @click.native="sendRegisrationRequest"
                            v-if="event.user.id !== null">
                        Записаться
                    </Button>
                    <EventModalPhoneForm @sendRegisrationRequest="sendRegisrationRequest"
                                         v-if="!event.require_visit_reason && event.user.id === null"/>
                </div>

                <div class="registration_form_agree" v-html="this.agreeText"></div>
            </div>
            <div class="registration_form_request" v-if="event.reservation_status !== null">
                <div class="registration_form_left empty_slot_form_left">
                    <div class="caption">Вы записаны в лист ожидания</div>
                </div>
                <div class="registration_form_right">
                    <Button color="white" size="m" :bg_color="this.bg" @click.native="sendCancelRegistrationRequest"
                            v-if="event.user.id !== null && event.reservation_status !== null">
                        Отменить запись
                    </Button>
                </div>

                <div class="registration_form_agree" v-html="this.agreeText"></div>
            </div>
        </template>


    </div>
</template>

<script>
    import EventModalPhoneForm from "./EventModalPhoneForm";
    import Button from "../Button";
    import {getWordDeclention} from "../../utils/text";
    import {storeToComputed} from "../mixins/storeToComputed";
    import FormSuccessBg from "../Images/FormSuccessBg";
    import FormWaitingBg from "../Images/FormWaitingBg";
    import EnterButton from "../Filters/EnterButton";

    export default {
        name: "EventModalRegistrationPanel",
        mixins: [storeToComputed],
        components: {
            EnterButton,
            EventModalPhoneForm,
            Button,
            FormSuccessBg,
            FormWaitingBg,
        },
        computed: {
            placesText() {
                return getWordDeclention(this.activeEventFreeSlots, ['место', 'места', 'мест']);
            },
            agreeText: function () {
                try {
                    let clubRules = this.$store.getters['events/clubRules'];
                    let offer = this.$store.getters['events/offer'];
                    let personalData = this.$store.getters['events/personalData'];
                    let links = [];

                    if (personalData.enable === true && personalData.link !== '') {
                        links.push('<a href="' + personalData.link + '" target="_blank">Персональных данных</a>')
                    } else {
                        links.push('<a href="https://fitbase.io/privacy" target="_blank">Персональных данных</a>')
                    }

                    if (offer.enable === true && offer.link !== '') {
                        links.push('<a href="' + offer.link + '" target="_blank">Офертой</a>')
                    }

                    if (clubRules.enable === true && clubRules.link !== '') {
                        links.push('<a href="' + clubRules.link + '" target="_blank">Правилами клуба</a>')
                    }

                    return 'Продолжая, вы соглашаетесь с обработкой ' + links.join(', ') + '.';
                } catch (e) {
                    return ''
                }
            }
        },
        methods: {
            closeModal: function () {
                this.$emit('closeModal');
            },
            sendRegisrationRequest: function ({phoneNumber}) {
                let event = this.event;
                let eventId = event.event_id;
                let clubId = event.club_id;
                this.$store.dispatch('registration/registrationRequest', {
                    eventId,
                    phoneNumber,
                    clubId,
                    apiUrl: this.domain
                });
            },
            sendCancelRegistrationRequest: function () {
                let event = this.event;
                let eventId = event.event_id;
                this.$store.dispatch('registration/registrationCancelRequest', {eventId, apiUrl: this.domain});
            }
        },
        props: {
            event: {
                type: Object,
                default: function () {
                    return {}
                }
            }
        },
    }
</script>
<style>
    #fitbase-widget .btn.enter-button > a {
        justify-content: center;
        color: #fff !important;
    }
    #fitbase-widget .btn.enter-button > a i path {
        fill: #fff !important;
    }
    .registration_form_request{
        flex-wrap: wrap;
    }
    .registration_form_agree{
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
</style>

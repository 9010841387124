<template>
	<div :class="['event_wrapper', event.extendedProps.isOver ? 'is_over' : '']" @click="this.openModal">
		<div class="event_stripe" :style="{ backgroundColor: event.extendedProps.border_color}"></div>
		<div class="event_content">
			<div class="event_top">
				<div class="event_time">
					<div class="event_time_from">{{event.extendedProps.display_start | formatEventDate}}</div>
					<div class="event_time_divider">-</div>
					<div class="event_time_to">{{event.extendedProps.display_end | formatEventDate}}</div>
				</div>
				<EventIcons :event="event.extendedProps" class="desktop"/>
			</div>
			<div class="event_title">{{ event.title }}</div>

			<div class="event_trainer" v-if="event.extendedProps.trainer.replacement">{{
				event.extendedProps.trainerReplacement.shortFio }}
			</div>
			<div :class="['event_trainer', event.extendedProps.trainer.replacement ? 'linethrough' : '']">{{
				event.extendedProps.trainer.shortFio }}
			</div>

			<div class="event_badge" v-if="event.extendedProps.need_register && event.extendedProps.reservation_status === null && !event.extendedProps.isOver">
				<template v-if="this.isHavingFreeSlots() && event.extendedProps.hide_number_of_available_setting != 1">
					<span class="badge">
						Еще {{ event.extendedProps.max_register - event.extendedProps.count_register }} мест
					</span>
				</template>
				<template v-else-if="(event.extendedProps.max_register - event.extendedProps.count_register) <= 0">
					<span class="badge danger">
						Нет свободных мест
					</span>
				</template>
				<template v-else-if="event.extendedProps.time_open !== ''">
					<span class="badge primary">
						Начало записи {{ event.extendedProps.time_open }}
					</span>
				</template>
        <template v-else-if="this.isHavingFreeSlots() && event.extendedProps.hide_number_of_available_setting == 1">
					<span class="badge success">
						Места есть
					</span>
        </template>
				<template v-else>
					<span class="badge danger">
						Запись окончена
					</span>
				</template>
			</div>

			<div class="event_badge" v-if="event.extendedProps.reservation_status !== null && !event.extendedProps.isOver">
				<span class="badge success" v-if="event.extendedProps.reservation_status === 'singed'">
					Вы записаны
				</span>
				<span class="badge" v-if="event.extendedProps.reservation_status === 'waiting'">
					Лист ожидания
				</span>
			</div>

			<EventIcons :event="event.extendedProps" class="desktop_small"/>
		</div>
	</div>
</template>

<script>
import EventIcons from "./EventIcons";

export default {
	name: "Event",
	components: {
		EventIcons
	},
	props: {
		event: {
			type: Object,
			default: function () {
				return {}
			}
		},
	},
	methods: {
		openModal: function () {
			let props = this._props.event.extendedProps;
			if (!props.isOver) {
				this.$emit('openBookingModal', {eventId: props.event_id});
			}
		},
		isHavingFreeSlots() {
			return (this.event.extendedProps.max_register - this.event.extendedProps.count_register > 0)
				&& !this.event.extendedProps.stop_registration;
		}
	},
	filters: {
		formatEventDate: function (value) {
			return value.slice(-8, -3)
		}
	},
}
</script>

<template>
		<div :class="['filters_top',{sm: isHavingSeveralClubs}]">
			<div class="select_wrapper">
				<SelectCustom v-if="filterOptions.clubs && filterOptions.clubs.length > 0 && isHavingSeveralClubs"
						className="club"
						:options="filterOptions.clubs"
						@on-select-changed="setClubFilter"
						:placeholder="this.getClub"
				/>
				<SelectCustom v-if="optionsIsReady"
						className="trainer"
						:options="filterOptions.trainers"
						@on-select-changed="setTrainerFilter"
						:placeholder="placeholders.trainer"
						ref="trainerFilter"
				/>
				<SelectCustom v-if="optionsIsReady"
						className="scheduleTemplate"
						:options="filterOptions.scheduleTemplates"
						@on-select-changed="setScheduleTemplateFilter"
						:placeholder="placeholders.scheduleTemplate"
						ref="scheduleTemplateFilter"
				/>
				<SelectCustom v-if="optionsIsReady"
						className="place"
						:options="filterOptions.places"
						@on-select-changed="setPlaceFilter"
						:placeholder="placeholders.place"
						ref="placeFilter"
				/>
			</div>
			<EnterButton />
		</div>
</template>

<script>
import SelectCustom from "../FormControl/SelectCustom";
import EnterButton from "./EnterButton";
import {storeToComputed} from "../mixins/storeToComputed";
import {eventBus} from "../../main";

const CLUB_PLACEHOLDER = 'Клуб';
const TRAINER_PLACEHOLDER = 'Тренер';
const SCHEDULE_TEMPLATE_PLACEHOLDER = 'Занятие';
const PLACE_PLACEHOLDER = 'Зал';

export default {
	name: "FiltersTop",
	components: {
		SelectCustom, EnterButton
	},
	mixins: [storeToComputed],
	data() {
		return {
			isFiltered: false,
			selectConfig: {
				clearable: false,
				searchable: false,
			},
			placeholders: {
				club: CLUB_PLACEHOLDER,
				trainer: TRAINER_PLACEHOLDER,
				scheduleTemplate: SCHEDULE_TEMPLATE_PLACEHOLDER,
				place: PLACE_PLACEHOLDER
			}
		}
	},
	methods: {
		getNewFilterValue: function(filterValue) {
			let newValue = [];
			let val = filterValue.value;
			if (val !== 0) {
				newValue.push(val);
			}

			return newValue;
		},
		setClubFilter: function ({value}) {
			this.$store.dispatch('filters/setClubFilter', value.value);
			eventBus.$emit('setClubFilter');

			this.$store.dispatch('filters/clearBranchFilter');

			this.setSelectDefaultPlaceholder('trainerFilter', this.placeholders.trainer)
			this.setSelectDefaultPlaceholder('scheduleTemplateFilter', this.placeholders.scheduleTemplate)
			this.setSelectDefaultPlaceholder('placeFilter', this.placeholders.place)
		},
		setTrainerFilter: function ({value}) {
			this.$store.dispatch(
				'filters/setTrainerFilter', this.getNewFilterValue(value)
			);
		},
		setScheduleTemplateFilter: function ({value}) {
			this.$store.dispatch(
				'filters/setScheduleTemplateFilter', this.getNewFilterValue(value)
			);
		},
		setPlaceFilter: function ({value}) {
			this.$store.dispatch(
				'filters/setPlaceFilter', this.getNewFilterValue(value)
			);
		},
		setSelectDefaultPlaceholder: function (refName, placeholder) {
			const ref = this.$refs[refName];
			if (ref) {
				const refEl = ref.$el;
				const vsSelected = refEl.querySelector('.vs__selected');
				if (vsSelected) {
					vsSelected.textContent = placeholder;
				}
			}
		}
	},
	computed: {
		getClub: function () {
			let club = this.filterOptions.clubs.filter(option => option.value === parseInt(this.$store.state.filters.filter.clubFilter, 10)).length ? this.filterOptions.clubs.filter(option => option.value === parseInt(this.$store.state.filters.filter.clubFilter, 10))[0] : this.filterOptions.clubs[0];
			return club.label;
		}
	}
}
</script>

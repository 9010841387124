export const SET_FILTERS_IS_FETCHING = 'SET_FILTERS_IS_FETCHING'
export const SET_FILTERS_IS_FETCHED = 'SET_FILTERS_IS_FETCHED'
export const SET_SELECTED_FILTER_SECTION = 'SET_SELECTED_FILTER_SECTION'
export const SET_CLUB_FILTER_OPTIONS = 'SET_CLUB_FILTER_OPTIONS'
export const SET_FILTER_OPTIONS = 'SET_FILTER_OPTIONS'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const CLEAR_BRANCH_FILTER = 'CLEAR_BRANCH_FILTER'
export const CLEAR_BRANCH_FILTER_OPTIONS = 'CLEAR_BRANCH_FILTER_OPTIONS'
export const SET_CLUB_FILTER = 'SET_CLUB_FILTER'
export const SET_TRAINER_FILTER = 'SET_TRAINER_FILTER'
export const SET_SCHEDULE_TEMPLATE_FILTER = 'SET_SCHEDULE_TEMPLATE_FILTER'
export const SET_PLACE_FILTER = 'SET_PLACE_FILTER'
export const SET_BRANCH_FILTER = 'SET_BRANCH_FILTER'
export const SET_ONLINE_FILTER = 'SET_ONLINE_FILTER'
export const SET_REGISTRATION_FILTER = 'SET_REGISTRATION_FILTER'
export const SET_PAY_FILTER = 'SET_PAY_FILTER'
